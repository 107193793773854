export const reMissionStatusConstants = {
	CreateReMissionStatus: { 
		REQUEST : 'CREATE_RE_MISSION_STATUS_REQUEST', 
		SUCCESS : 'CREATE_RE_MISSION_STATUS_SUCCESS',
		FAILURE : 'CREATE_RE_MISSION_STATUS_FAILURE',
	},
	UpdateReMissionStatus: { 
		REQUEST : 'UPDATE_RE_MISSION_STATUS_REQUEST', 
		SUCCESS : 'UPDATE_RE_MISSION_STATUS_SUCCESS',
		FAILURE : 'UPDATE_RE_MISSION_STATUS_FAILURE',
	},
	DeleteReMissionStatus: { 
		REQUEST : 'DELETE_RE_MISSION_STATUS_REQUEST', 
		SUCCESS : 'DELETE_RE_MISSION_STATUS_SUCCESS',
		FAILURE : 'DELETE_RE_MISSION_STATUS_FAILURE',
	},
	RestoreReMissionStatus: { 
		REQUEST : 'RESTORE_RE_MISSION_STATUS_REQUEST', 
		SUCCESS : 'RESTORE_RE_MISSION_STATUS_SUCCESS',
		FAILURE : 'RESTORE_RE_MISSION_STATUS_FAILURE',
	},
	GetReMissionStatusesList: { 
		REQUEST : 'GET_RE_MISSION_STATUSES_LIST_REQUEST', 
		SUCCESS : 'GET_RE_MISSION_STATUSES_LIST_SUCCESS',
		FAILURE : 'GET_RE_MISSION_STATUSES_LIST_FAILURE',
	},
	GetReMissionStatusesPaged: { 
		REQUEST : 'GET_RE_MISSION_STATUSES_PAGED_REQUEST', 
		SUCCESS : 'GET_RE_MISSION_STATUSES_PAGED_SUCCESS',
		FAILURE : 'GET_RE_MISSION_STATUSES_PAGED_FAILURE',
	},
	ClearReMissionStatusesValues: 'RE_MISSION_STATUSES_VALUES_CLEAR'
}