/* eslint-disable sort-keys */
import {
	messageTemplateConstants
}from './constants'

const initialState = {
	isSending        : false,
	isLoading        : false,
	createResult     : false,
	list             : [],
	error            : null,
	addDocResult     : null,
	docSample        : null,
	changed          : null,
	docScale         : null,
	docScaleFile     : null,
	docSampleExample : []
}

export default function (state = initialState, action){
	switch (action.type){
		case messageTemplateConstants.GetOrgDocumentScales.REQUEST:
			return { ...state,
				isLoading : true,
				docScale  : null }
		case messageTemplateConstants.GetOrgDocumentScales.SUCCESS:
			return {
				...state,
				isLoading : false,
				docScale  : action.payload.result
			}
		case messageTemplateConstants.GetOrgDocumentScales.FAILURE:
			return { ...state,
				isLoading : false,
				error     : action.payload.err.data,
				docScale  : null }
		case messageTemplateConstants.GetOrgDocumentScales.CLEAR:
			return { ...state,
				isLoading : false,
				docScale  : null }
		case messageTemplateConstants.DownloadDocsScale.REQUEST:
			return { ...state,
				isLoading    : true,
				docScaleFile : []}
		case messageTemplateConstants.DownloadDocsScale.SUCCESS:
			return {
				...state,
				isLoading    : false,
				docScaleFile : action.payload.result
			}
		case messageTemplateConstants.DownloadDocsScale.FAILURE:
			return { ...state,
				isLoading    : false,
				error        : action.payload.err.data,
				docScaleFile : []}
		case messageTemplateConstants.DownloadDocsScaleExample.REQUEST:
			return { ...state,
				isLoading        : true,
				docSampleExample : []}
		case messageTemplateConstants.DownloadDocsScaleExample.SUCCESS:
			return {
				...state,
				isLoading        : false,
				docSampleExample : action.payload.result
			}
		case messageTemplateConstants.DownloadDocsScaleExample.FAILURE:
			return { ...state,
				isLoading        : false,
				error            : action.payload.err.data,
				docSampleExample : []}
		case messageTemplateConstants.GetMessageTemplates.REQUEST:
			return {
				...state,
				isLoading : true,
				list      : []
			}
		case messageTemplateConstants.GetMessageTemplates.SUCCESS:
			return {
				...state,
				isLoading : false,
				list      : action.payload.result
			}
		case messageTemplateConstants.GetMessageTemplates.FAILURE:
			return {
				...state,
				isLoading : false,
				error     : action.payload.err.data,
				list      : []
			}
		case messageTemplateConstants.Create.REQUEST:
			return {
				...state,
				isLoading    : true,
				createResult : false
			}
		case messageTemplateConstants.Create.SUCCESS:
			return {
				...state,
				isLoading    : false,
				createResult : true
			}
		case messageTemplateConstants.Create.FAILURE:
			return {
				...state,
				isLoading    : false,
				createResult : false,
				error        : action.payload.err.data
			}
		case messageTemplateConstants.GetOrgDocumentScale.REQUEST:
			return {
				...state,
				isLoading : true
			}
		case messageTemplateConstants.GetOrgDocumentScale.SUCCESS:
			return {
				...state,
				isLoading : false,
				docSample : null
			}
		case messageTemplateConstants.GetOrgDocumentScale.FAILURE:
			return {
				...state,
				isLoading : false,
				docSample : null,
				error     : action.payload.err.data
			}
		case messageTemplateConstants.GetOrgDocumentScale.CLEAR:
			return {
				...state,
				isLoading : false,
				docSample : null
			}
		case messageTemplateConstants.AddOrgDocumentScale.REQUEST:
			return {
				...state,
				isLoading    : true
			}
		case messageTemplateConstants.AddOrgDocumentScale.SUCCESS:
			return {
				...state,
				isLoading    : false,
				addDocResult : action.payload.result,
			}
		case messageTemplateConstants.AddOrgDocumentScale.FAILURE:
			return {
				...state,
				isLoading    : false,
				addDocResult : null,
				error        : action.payload.err.data
			}
		case messageTemplateConstants.AddOrgDocumentScale.CLEAR:
			return {
				...state,
				isLoading    : false,
				addDocResult : null
			}
		case messageTemplateConstants.Delete.REQUEST:
			return {
				...state,
				isLoading    : true,
				createResult : false
			}
		case messageTemplateConstants.Delete.SUCCESS:
			return {
				...state,
				isLoading    : false,
				createResult : true
			}
		case messageTemplateConstants.Delete.FAILURE:
			return {
				...state,
				isLoading    : false,
				createResult : false,
				error        : action.payload.err.data
			}
		default:
			return state
	}
}