import {
	managementConstants
}from "./constants"
import notice from "../../../components/Notice"
import isRu from "../../../helpers/isRu"
import organizationLocalization from "../Organization/localization"
import blockLocalization from "../Block/localization"
import disciplinaryActionLocalization from "../DisciplinaryActions/localization"

const initialState = {
	//getUsers;
	getUsersLoading : false,
	users           : null,
	getUsersError   : null,

	//getUser;
	oneUserLoading : false,
	oneUser        : null,
	oneUserError   : null,

	//block;
	blockLoading : false,
	isBlocked    : null,
	blockError   : null,

	//unblock;
	unblockLoading : false,
	isUnblocked    : null,
	unblockError   : null,

	//getOrgUsers;
	getOrgUsersLoading : false,
	orgUsers           : null,
	getOrgUsersError   : null,

	//getOrgUserList;
	getOrgUserListLoading : false,
	getOrgUserListResult : null,
	getOrgUserListError : null,

	//getOrgUser;
	oneOrgUserLoading : false,
	oneOrgUser        : null,
	oneOrgUserError   : null,

	//getUserExecut;
	userExecutLoading : false,
	userExecut        : null,
	userExecutError   : null,

	//getUserExecutHistory;
	userExecutHistoryLoading : false,
	userExecutHistory        : null,
	userExecutHistoryError   : null,

	//setUserExecut;
	setUserExecutLoading : false,
	setUserExecutSuccess : null,
	setUserExecutError   : null,

	//setTempExternalAdmin;
	setTempExternalAdminLoading : false,
	setTempExternalAdminSuccess : null,
	setTempExternalAdminError   : null,

	//unsetTempExternalAdmin;
	unsetTempExternalAdminLoading : false,
	unsetTempExternalAdminSuccess : null,
	unsetTempExternalAdminError   : null,

	//removeUserFromReOrg;
	removeUserFromReOrgLoading : false,
	removeUserFromReOrgSuccess : null,
	removeUserFromReOrgError   : null,

	//removeDisciplinaryActions;
	removeDisciplinaryActionsLoading : false,
	removeDisciplinaryActionsSuccess : false,
	removeDisciplinaryActionsError   : null,

	//getRemovedOrgUsers;
	getRemovedOrgUsersLoading : false,
	removedOrgUsers           : null,
	getRemovedOrgUsersError   : null,

	//inviteToReOrg;
	inviteToReOrgLoading : false,
	inviteToReOrgSuccess : null,
	inviteToReOrgError   : null,

	//getInvitations;
	getInvitationsLoading : false,
	invitations           : null,
	getInvitationsError   : null,

	//getInvitation;
	getInvitationLoading : false,
	invitation           : null,
	getInvitationError   : null,

	//responseToInvitation;
	responseToInvitationLoading : false,
	responseToInvitationSuccess : null,
	responseToInvitationError   : null,

	//createDisciplinaryAction;
	createDisciplinaryActionLoading : false,
	createDisciplinaryActionSuccess : null,
	createDisciplinaryActionError   : null,

	//getDisciplinaryActions;
	getDisciplinaryActionsLoading : false,
	disciplinaryActions           : null,
	getDisciplinaryActionsError   : null,

	//changeRoles;
	changeRolesLoading : false,
	changeRolesSuccess : null,
	changeRolesError   : null,

	//addChildOrg;
	addChildOrgLoading : false,
	addChildOrgSuccess : null,
	addChildOrgError   : null,

	//removeChildOrg;
	removeChildOrgLoading : false,
	removeChildOrgSuccess : null,
	removeChildOrgError   : null,

	//getParentChildOrgs;
	getParentChildOrgsLoading : false,
	parentChildOrgs           : null,
	getParentChildOrgsError   : null,

	//getInstitutionChildOrgs;
	getInstitutionChildOrgsLoading : false,
	institutionChildOrgs           : null,
	getInstitutionChildOrgsError   : null,

	//getChildInfo;
	getChildInfoLoading : false,
	childInfo           : null,
	getChildInfoError   : null,

	//getChildOrgInvitations;
	getChildOrgInvitationsLoading : false,
	childOrgInvitations           : null,
	getChildOrgInvitationsError   : null,

	//getChildOrgInvitation;
	getChildOrgInvitationLoading : false,
	childOrgInvitation           : null,
	getChildOrgInvitationError   : null,

	//responseToChildOrgInvitation;
	responseToChildOrgInvitationLoading : false,
	responseToChildOrgInvitationSuccess : null,
	responseToChildOrgInvitationError   : null,

	//reOrganizationUnits
	orgUnitsLoading   : false,
	orgUnits          : null,
	isOrgUnitsChanged : false,
	orgUnitsError     : null,

	//ReassignSubUnit
	subunitOrgList        : null,
	orgSubunitError       : null,
	subunitOrgUserChanged : false,
	orgSubUnitLoading     : false,

	//DeleteUserIdentificationNumber
	deleteIdNumberLoading : false,
	deleteIdNumberError   : null,
	deleteIdNumberChanged : false,

	//ChangeOrgUnitToOrg
	changeOrgUnitLoading : false,
	orgUnitChanged       : false,

	//downloadRegisterOrgFileExcel
	downloadRegisterExcelSending: false,

	//setExternalAdminToSelfRegistratedJurdical
	externalAdminToSelfRegistratedJurdicalLoading : false,
	externalAdminToSelfRegistratedJurdicalResult  : null
}

export default function (state = initialState, action){
	switch (action.type){
		case managementConstants.ChangeOrgUnitToOrg.REQUEST :
			return {
				...state,
				changeOrgUnitLoading : true,
				orgUnitChanged       : false
			}

		case managementConstants.ChangeOrgUnitToOrg.FAILURE :
			return {
				...state,
				changeOrgUnitLoading: false
			}

		case managementConstants.ChangeOrgUnitToOrg.SUCCESS:
			return {
				...state,
				changeOrgUnitLoading : false,
				orgUnitChanged       : true
			}

		case managementConstants.ChangeOrgUnitToOrg.CLEAR:
			return {
				...state,
				changeOrgUnitLoading : false,
				orgUnitChanged       : false
			}

		case managementConstants.DeleteUserIdentificationNumber.REQUEST :
			return {
				...state,
				deleteIdNumberLoading : true,
				deleteIdNumberError   : null,
				deleteIdNumberChanged : false
			}

		case managementConstants.DeleteUserIdentificationNumber.FAILURE :
			return {
				...state,
				deleteIdNumberError   : action.payload.result,
				deleteIdNumberLoading : false
			}

		case managementConstants.DeleteUserIdentificationNumber.SUCCESS:
			return {
				...state,
				deleteIdNumberLoading : false,
				deleteIdNumberChanged : true
			}

		case managementConstants.DeleteUserIdentificationNumber.CLEAR:
			return {
				...state,
				deleteIdNumberLoading : false,
				deleteIdNumberError   : null,
				deleteIdNumberChanged : false
			}

		case managementConstants.GetSubunitOrgsList.REQUEST :
		case managementConstants.ReassignOrgUser.REQUEST:
			return {
				...state,
				orgSubUnitLoading     : true,
				subunitOrgList        : null,
				subunitOrgUserChanged : false,
				orgSubunitError       : null
			}

		case managementConstants.GetSubunitOrgsList.SUCCESS :
			return {
				...state,
				orgSubUnitLoading : false,
				subunitOrgList    : action.payload.result
			}

		case managementConstants.ReassignOrgUser.SUCCESS :
			return {
				...state,
				orgSubUnitLoading     : false,
				subunitOrgUserChanged : true
			}

		case managementConstants.GetSubunitOrgsList.FAILURE :
		case managementConstants.ReassignOrgUser.FAILURE:
			return {
				...state,
				subunitOrgUserChanged : false,
				orgSubunitError       : action.payload.err.data,
				orgSubUnitLoading     : false
			}

		case managementConstants.GetSubunitOrgsList.CLEAR :
			return {
				...state,
				subunitOrgList        : null,
				orgSubunitError       : null,
				subunitOrgUserChanged : false,
				orgSubUnitLoading     : false
			}

		//getUsers;
		case managementConstants.GetUsers.REQUEST :
			return {
				...state,
				getUsersLoading : true,
				users           : null,
				getUsersError   : null
			}
		case managementConstants.GetUsers.SUCCESS :
			return {
				...state,
				getUsersLoading : false,
				users           : action.payload.result
			}
		case managementConstants.GetUsers.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getUsersLoading : false,
				getUsersError   : action.payload.err.data
			}

		//getUser;
		case managementConstants.GetUser.REQUEST :
			return {
				...state,
				oneUserLoading : true,
				oneUser        : null,
				oneUserError   : null
			}
		case managementConstants.GetUser.SUCCESS :
			return {
				...state,
				oneUserLoading : false,
				oneUser        : action.payload.result
			}
		case managementConstants.GetUser.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				oneUserLoading : false,
				oneUserError   : action.payload.err.data
			}

		//block;
		case managementConstants.Block.REQUEST :
			return {
				...state,
				blockLoading : true,
				isBlocked    : null,
				blockError   : null
			}
		case managementConstants.Block.SUCCESS :
			notice("success", blockLocalization.successBlock[isRu()])
			return {
				...state,
				blockLoading : false,
				isBlocked    : action.payload.result,
				isUnblocked  : false
			}
		case managementConstants.Block.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				blockLoading : false,
				blockError   : action.payload.err.data
			}

		//unblock;
		case managementConstants.Unblock.REQUEST :
			return {
				...state,
				unblockLoading : true,
				isUnblocked    : null,
				unblockError   : null
			}
		case managementConstants.Unblock.SUCCESS :
			notice("success", blockLocalization.successUnblock[isRu()])
			return {
				...state,
				unblockLoading : false,
				isUnblocked    : action.payload.result,
				isBlocked      : false
			}
		case managementConstants.Unblock.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				unblockLoading : false,
				unblockError   : action.payload.err.data
			}

		//getOrgUsers;
		case managementConstants.GetOrgUsers.REQUEST :
			return {
				...state,
				getOrgUsersLoading : true,
				orgUsers           : null,
				getOrgUsersError   : null
			}
		case managementConstants.GetOrgUsers.SUCCESS :
			return {
				...state,
				getOrgUsersLoading : false,
				orgUsers           : action.payload.result
			}
		case managementConstants.GetOrgUsers.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getOrgUsersLoading : false,
				getOrgUsersError   : action.payload.err.data
			}
		//getOrgUserList;
		case managementConstants.GetOrgUserList.REQUEST :
			return {
				...state,
				getOrgUserListLoading : true,
			}
		case managementConstants.GetOrgUserList.SUCCESS :
			return {
				...state,
				getOrgUserListLoading : false,
				getOrgUserListResult  : action.payload.result
			}
		case managementConstants.GetOrgUserList.FAILURE :
			return {
				...state,
				getOrgUserListLoading : false,
				getOrgUserListError   : action.payload.err.data
			}
		case managementConstants.GetOrgUserList.CLEAR :
			return {
				...state,
				getOrgUserListLoading : false,
				getOrgUserListResult : null,
				getOrgUserListError : null
			}
		//getOrgUser;
		case managementConstants.GetOrgUser.REQUEST :
			return {
				...state,
				oneOrgUserLoading : true,
				oneOrgUser        : null,
				oneOrgUserError   : null
			}
		case managementConstants.GetOrgUser.SUCCESS :
			return {
				...state,
				oneOrgUserLoading : false,
				oneOrgUser        : action.payload.result
			}
		case managementConstants.GetOrgUser.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				oneOrgUserLoading : false,
				oneOrgUserError   : action.payload.err.data
			}
		case managementConstants.GetOrgUser.CLEAR :
			return {
				...state,
				oneOrgUserLoading : false,
				oneOrgUser        : null,
				oneOrgUserError   : null
			}

		//getUserExecut;
		case managementConstants.GetUserExecut.REQUEST :
			return {
				...state,
				userExecutLoading          : true,
				userExecut                 : null,
				userExecutError            : null,
				setUserExecutLoading       : false,
				setUserExecutSuccess       : null,
				setUserExecutError         : null,
				removeUserFromReOrgSuccess : null,
				removeUserFromReOrgLoading : false
			}
		case managementConstants.GetUserExecut.SUCCESS :
			return {
				...state,
				userExecutLoading : false,
				userExecut        : action.payload.result
			}
		case managementConstants.GetUserExecut.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				userExecutLoading : false,
				userExecutError   : action.payload.err.data
			}
		case managementConstants.GetUserExecut.CLEAR :
			return {
				...state,
				userExecutLoading : false,
				userExecut        : null,
				userExecutError   : null
			}

		//getUserExecutHistory;
		case managementConstants.GetUserExecutHistory.REQUEST :
			return {
				...state,
				userExecutHistoryLoading : true,
				userExecutHistoryError   : null
			}
		case managementConstants.GetUserExecutHistory.SUCCESS :
			return {
				...state,
				userExecutHistoryLoading : false,
				userExecutHistory        : action.payload.result
			}
		case managementConstants.GetUserExecutHistory.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				userExecutHistoryLoading : false,
				userExecutHistory        : null,
				userExecutHistoryError   : action.payload.err.data
			}
		case managementConstants.GetUserExecutHistory.CLEAR :
			return {
				...state,
				userExecutHistoryLoading : false,
				userExecutHistory        : null,
				userExecutHistoryError   : null
			}

		//setUserExecut;
		case managementConstants.SetUserExecut.REQUEST :
			return {
				...state,
				setUserExecutLoading : true,
				setUserExecutSuccess : null,
				setUserExecutError   : null
			}
		case managementConstants.SetUserExecut.SUCCESS :
			notice("success", organizationLocalization.successSetUserExecut[isRu()])
			return {
				...state,
				setUserExecutLoading : false,
				setUserExecutSuccess : action.payload.result
			}
		case managementConstants.SetUserExecut.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				setUserExecutLoading : false,
				setUserExecutError   : action.payload.err.data
			}

		//setTempExternalAdmin;
		case managementConstants.SetTempExternalAdmin.REQUEST :
			return {
				...state,
				setTempExternalAdminLoading   : true,
				setTempExternalAdminSuccess   : null,
				setTempExternalAdminError     : null,
				unsetTempExternalAdminSuccess : null,
				unsetTempExternalAdminError   : null
			}
		case managementConstants.SetTempExternalAdmin.SUCCESS :
			notice("success", organizationLocalization.successSetTempExternalAdmin[isRu()])
			return {
				...state,
				setTempExternalAdminLoading : false,
				setTempExternalAdminSuccess : action.payload.result
			}
		case managementConstants.SetTempExternalAdmin.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				setTempExternalAdminLoading : false,
				setTempExternalAdminError   : action.payload.err.data
			}

		//unsetTempExternalAdmin;
		case managementConstants.UnsetTempExternalAdmin.REQUEST :
			return {
				...state,
				unsetTempExternalAdminLoading : true,
				unsetTempExternalAdminSuccess : null,
				unsetTempExternalAdminError   : null
			}
		case managementConstants.UnsetTempExternalAdmin.SUCCESS :
			notice("success", organizationLocalization.successUnsetTempExternalAdmin[isRu()])
			return {
				...state,
				unsetTempExternalAdminLoading : false,
				unsetTempExternalAdminSuccess : action.payload.result
			}
		case managementConstants.UnsetTempExternalAdmin.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				unsetTempExternalAdminLoading : false,
				unsetTempExternalAdminError   : action.payload.err.data
			}

		//removeUserFromReOrg;
		case managementConstants.RemoveUserFromReOrg.REQUEST :
			return {
				...state,
				removeUserFromReOrgLoading : true,
				removeUserFromReOrgSuccess : null,
				removeUserFromReOrgError   : null
			}
		case managementConstants.RemoveUserFromReOrg.SUCCESS :
			notice("success", organizationLocalization.removeUserFromReOrg[isRu()])
			return {
				...state,
				removeUserFromReOrgLoading : false,
				removeUserFromReOrgSuccess : action.payload.result
			}
		case managementConstants.RemoveUserFromReOrg.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				removeUserFromReOrgLoading : false,
				removeUserFromReOrgError   : action.payload.err.data
			}

		//removeDisciplinaryActions;
		case managementConstants.RemoveDisciplinaryActions.REQUEST :
			return {
				...state,
				removeDisciplinaryActionsLoading : true,
				removeDisciplinaryActionsSuccess : null,
				removeDisciplinaryActionsError   : null
			}
		case managementConstants.RemoveDisciplinaryActions.SUCCESS :
			return {
				...state,
				removeDisciplinaryActionsLoading : false,
				removeDisciplinaryActionsSuccess : action.payload.result
			}
		case managementConstants.RemoveDisciplinaryActions.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				removeDisciplinaryActionsLoading : false,
				removeDisciplinaryActionsError   : action.payload.err.data
			}

		//getRemovedOrgUsers;
		case managementConstants.GetRemovedOrgUsers.REQUEST :
			return {
				...state,
				getRemovedOrgUsersLoading : true,
				removedOrgUsers           : null,
				getRemovedOrgUsersError   : null
			}
		case managementConstants.GetRemovedOrgUsers.SUCCESS :
			return {
				...state,
				getRemovedOrgUsersLoading : false,
				removedOrgUsers           : action.payload.result
			}
		case managementConstants.GetRemovedOrgUsers.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getRemovedOrgUsersLoading : false,
				getRemovedOrgUsersError   : action.payload.err.data
			}

		//inviteToReOrg;
		case managementConstants.InviteToReOrg.REQUEST :
			return {
				...state,
				inviteToReOrgLoading : true,
				inviteToReOrgSuccess : null,
				inviteToReOrgError   : null
			}
		case managementConstants.InviteToReOrg.SUCCESS :
			notice("success", organizationLocalization.successInviteToReOrg[isRu()])
			return {
				...state,
				inviteToReOrgLoading : false,
				inviteToReOrgSuccess : action.payload.result
			}
		case managementConstants.InviteToReOrg.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				inviteToReOrgLoading : false,
				inviteToReOrgError   : action.payload.err.data
			}

		//getInvitations;
		case managementConstants.GetInvitations.REQUEST :
			return {
				...state,
				getInvitationsLoading : true,
				invitations           : null,
				getInvitationsError   : null,
				inviteToReOrgSuccess  : null,
				inviteToReOrgError    : null
			}
		case managementConstants.GetInvitations.SUCCESS :
			return {
				...state,
				getInvitationsLoading : false,
				invitations           : action.payload.result
			}
		case managementConstants.GetInvitations.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getInvitationsLoading : false,
				getInvitationsError   : action.payload.err.data
			}

		//getInvitation;
		case managementConstants.GetInvitation.REQUEST :
			return {
				...state,
				getInvitationLoading        : true,
				invitation                  : null,
				getInvitationError          : null,
				responseToInvitationSuccess : null,
				responseToInvitationError   : null
			}
		case managementConstants.GetInvitation.SUCCESS :
			return {
				...state,
				getInvitationLoading : false,
				invitation           : action.payload.result
			}
		case managementConstants.GetInvitation.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getInvitationLoading : false,
				getInvitationError   : action.payload.err.data
			}

		//responseToInvitation;
		case managementConstants.ResponseToInvitation.REQUEST :
			return {
				...state,
				responseToInvitationLoading : true,
				responseToInvitationSuccess : null,
				responseToInvitationError   : null
			}
		case managementConstants.ResponseToInvitation.SUCCESS :
			notice("success", organizationLocalization.successResponseToInvitationLoading[isRu()])
			return {
				...state,
				responseToInvitationLoading : false,
				responseToInvitationSuccess : action.payload.result
			}
		case managementConstants.ResponseToInvitation.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				responseToInvitationLoading : false,
				responseToInvitationError   : action.payload.err.data
			}

		//createDisciplinaryAction;
		case managementConstants.CreateDisciplinaryAction.REQUEST :
			return {
				...state,
				createDisciplinaryActionLoading : true,
				createDisciplinaryActionSuccess : null,
				createDisciplinaryActionError   : null
			}
		case managementConstants.CreateDisciplinaryAction.SUCCESS :
			notice("success", disciplinaryActionLocalization.successCreateDisciplinaryAction[isRu()])
			return {
				...state,
				createDisciplinaryActionLoading : false,
				createDisciplinaryActionSuccess : action.payload.result
			}
		case managementConstants.CreateDisciplinaryAction.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				createDisciplinaryActionLoading : false,
				createDisciplinaryActionError   : action.payload.err.data
			}

		//getDisciplinaryActions;
		case managementConstants.GetDisciplinaryActions.REQUEST :
			return {
				...state,
				getDisciplinaryActionsLoading : true,
				disciplinaryActions           : null,
				getDisciplinaryActionsError   : null
			}
		case managementConstants.GetDisciplinaryActions.SUCCESS :
			return {
				...state,
				getDisciplinaryActionsLoading : false,
				disciplinaryActions           : action.payload.result
			}
		case managementConstants.GetDisciplinaryActions.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getDisciplinaryActionsLoading : false,
				getDisciplinaryActionsError   : action.payload.err.data
			}

		//clearDisciplinaryActions;
		case managementConstants.ClearDisciplinaryActions.SUCCESS :
			return {
				...state,
				createDisciplinaryActionLoading  : false,
				createDisciplinaryActionSuccess  : null,
				createDisciplinaryActionError    : null,
				removeDisciplinaryActionsLoading : false,
				removeDisciplinaryActionsSuccess : null,
				removeDisciplinaryActionsError   : null
			}

		//changeRoles;
		case managementConstants.ChangeRoles.REQUEST :
			return {
				...state,
				changeRolesLoading : true,
				changeRolesSuccess : null,
				changeRolesError   : null
			}
		case managementConstants.ChangeRoles.SUCCESS :
			notice("success", organizationLocalization.successChangeRoles[isRu()])
			return {
				...state,
				changeRolesLoading : false,
				changeRolesSuccess : action.payload.result
			}
		case managementConstants.ChangeRoles.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				changeRolesLoading : false,
				changeRolesError   : action.payload.err.data
			}

		//addChildOrg;
		case managementConstants.AddChildOrg.REQUEST :
			return {
				...state,
				addChildOrgLoading : true,
				addChildOrgSuccess : null,
				addChildOrgError   : null
			}
		case managementConstants.AddChildOrg.SUCCESS :
			notice("success", organizationLocalization.successAddChildOrg[isRu()])
			return {
				...state,
				addChildOrgLoading : false,
				addChildOrgSuccess : action.payload.result
			}
		case managementConstants.AddChildOrg.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				addChildOrgLoading : false,
				addChildOrgError   : action.payload.err.data
			}

		//removeChildOrg;
		case managementConstants.RemoveChildOrg.REQUEST :
			return {
				...state,
				removeChildOrgLoading : true,
				removeChildOrgSuccess : null,
				removeChildOrgError   : null
			}
		case managementConstants.RemoveChildOrg.SUCCESS :
			notice("success", organizationLocalization.successRemoveChildOrg[isRu()])
			return {
				...state,
				removeChildOrgLoading : false,
				removeChildOrgSuccess : action.payload.result
			}
		case managementConstants.RemoveChildOrg.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				removeChildOrgLoading : false,
				removeChildOrgError   : action.payload.err.data
			}

		//getParentChildOrgs;
		case managementConstants.ParentChildOrgs.REQUEST :
			return {
				...state,
				addChildOrgSuccess  : null,
				getChildOrgsLoading : true,
				childOrgs           : null,
				getChildOrgsError   : null
			}
		case managementConstants.ParentChildOrgs.SUCCESS :
			return {
				...state,
				getChildOrgsLoading : false,
				childOrgs           : action.payload.result
			}
		case managementConstants.ParentChildOrgs.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getChildOrgsLoading : false,
				getChildOrgsError   : action.payload.err.data
			}

		//getInstitutionChildOrgs;
		case managementConstants.InstitutionChildOrgs.REQUEST :
			return {
				...state,
				addChildOrgSuccess  : null,
				getChildOrgsLoading : true,
				childOrgs           : null,
				getChildOrgsError   : null
			}
		case managementConstants.InstitutionChildOrgs.SUCCESS :
			return {
				...state,
				getChildOrgsLoading : false,
				childOrgs           : action.payload.result
			}
		case managementConstants.InstitutionChildOrgs.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getInstitutionChildOrgsLoading : false,
				getInstitutionChildOrgsError   : action.payload.err.data
			}

		//getChildInfo;
		case managementConstants.GetChildInfo.REQUEST :
			return {
				...state,
				getChildInfoLoading : true,
				childInfo           : null,
				getChildInfoError   : null
			}
		case managementConstants.GetChildInfo.SUCCESS :
			return {
				...state,
				getChildInfoLoading : false,
				childInfo           : action.payload.result
			}
		case managementConstants.GetChildInfo.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getChildInfoLoading : false,
				getChildInfoError   : action.payload.err.data
			}

		//getChildOrgInvitations;
		case managementConstants.GetChildOrgInvitations.REQUEST :
			return {
				...state,
				responseToChildOrgInvitationSuccess : false,
				getChildOrgInvitationsLoading       : true,
				childOrgInvitations                 : null,
				getChildOrgInvitationsError         : null
			}
		case managementConstants.GetChildOrgInvitations.SUCCESS :
			return {
				...state,
				getChildOrgInvitationsLoading : false,
				childOrgInvitations           : action.payload.result
			}
		case managementConstants.GetChildOrgInvitations.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getChildOrgInvitationsLoading : false,
				getChildOrgInvitationsError   : action.payload.err.data
			}

		//getChildOrgInvitation;
		case managementConstants.GetChildOrgInvitation.REQUEST :
			return {
				...state,
				getChildOrgInvitationLoading        : true,
				childOrgInvitation                  : null,
				getChildOrgInvitationError          : null,
				responseToChildOrgInvitationSuccess : null,
				responseToChildOrgInvitationError   : null
			}
		case managementConstants.GetChildOrgInvitation.SUCCESS :
			return {
				...state,
				getChildOrgInvitationLoading : false,
				childOrgInvitation           : action.payload.result
			}
		case managementConstants.GetChildOrgInvitation.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				getChildOrgInvitationLoading : false,
				getChildOrgInvitationError   : action.payload.err.data
			}

		//responseToChildOrgInvitation;
		case managementConstants.ResponseToChildOrgInvitation.REQUEST :
			return {
				...state,
				responseToChildOrgInvitationLoading : true,
				responseToChildOrgInvitationSuccess : null,
				responseToChildOrgInvitationError   : null
			}
		case managementConstants.ResponseToChildOrgInvitation.SUCCESS :
			Boolean(action.payload.result) && notice("success", organizationLocalization.successResponseToInvitationLoading[isRu()])
			return {
				...state,
				responseToChildOrgInvitationLoading : false,
				responseToChildOrgInvitationSuccess : action.payload.result
			}
		case managementConstants.ResponseToChildOrgInvitation.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				responseToChildOrgInvitationLoading : false,
				responseToChildOrgInvitationError   : action.payload.err.data
			}

		//reOrganizationUnits
		case managementConstants.GetOrganizationUnits.CLEAR :
			return {
				...state,
				orgUnitsLoading   : false,
				orgUnits          : null,
				isOrgUnitsChanged : false,
				orgUnitsError     : null
			}
		case managementConstants.GetOrganizationUnits.REQUEST :
			return {
				...state,
				orgUnitsLoading : true,
				orgUnits        : null,
				orgUnitsError   : null
			}
		case managementConstants.GetOrganizationUnits.SUCCESS :
			return {
				...state,
				orgUnitsLoading : false,
				orgUnits        : action.payload.result
			}
		case managementConstants.GetOrganizationUnits.FAILURE :
			return {
				...state,
				orgUnitsLoading : false,
				orgUnitsError   : action.payload.err.data
			}

		case managementConstants.ChangeOrganizationUnit.REQUEST :
		case managementConstants.DeleteRestoreOrganizationUnit.REQUEST :
			return {
				...state,
				orgUnitsLoading   : true,
				isOrgUnitsChanged : false,
				orgUnitsError     : null
			}
		case managementConstants.ChangeOrganizationUnit.SUCCESS :
		case managementConstants.DeleteRestoreOrganizationUnit.SUCCESS :
			return {
				...state,
				orgUnitsLoading   : false,
				isOrgUnitsChanged : action.payload.result
			}
		case managementConstants.ChangeOrganizationUnit.FAILURE :
		case managementConstants.DeleteRestoreOrganizationUnit.FAILURE :
			return {
				...state,
				orgUnitsLoading : false,
				orgUnitsError   : action.payload.err.data
			}
		case managementConstants.ExportRegisteredReOrgsFormExcel.REQUEST :
			return {
				...state,
				downloadRegisterExcelSending: true
			}
		case managementConstants.ExportRegisteredReOrgsFormExcel.SUCCESS :
		case managementConstants.ExportRegisteredReOrgsFormExcel.FAILURE :
			return {
				...state,
				downloadRegisterExcelSending: false
			}
		case managementConstants.SetExternalAdminToSelfRegistratedJurdical.REQUEST :
			return {
				...state,
				externalAdminToSelfRegistratedJurdicalLoading : true,
				externalAdminToSelfRegistratedJurdicalResult  : null
			}
		case managementConstants.SetExternalAdminToSelfRegistratedJurdical.SUCCESS :
			return {
				...state,
				externalAdminToSelfRegistratedJurdicalResult  : action.payload.result,
				externalAdminToSelfRegistratedJurdicalLoading : false
			}
		case managementConstants.SetExternalAdminToSelfRegistratedJurdical.FAILURE :
			return {
				...state,
				error 										  : action.payload.err.data,
				externalAdminToSelfRegistratedJurdicalLoading : false,
			}
		case managementConstants.SetExternalAdminToSelfRegistratedJurdical.CLEAR :
			return {
				...state,
				externalAdminToSelfRegistratedJurdicalLoading : false,
				externalAdminToSelfRegistratedJurdicalResult  : null
			}
		case managementConstants.ClearIsBlockedUnblockedValues : 
			return {
				...state,
				isBlocked: null,
				isUnblocked: null,
			}
		default :
			return state
	}
}
