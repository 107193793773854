import {
    interactionWithAPIConstants
  } from './constants'
  
  const initialState =
  {
    interactionsWithAPIList: null,
    interactionsWithAPIPaged: null,
    interactionsWithAPILoading: false,
    interactionWithAPICRUDvalue : {
      create : null,
      restore : null,
      update : null,
      delete : null
    },
    interactionWithAPICRUDLoading: false,
    error: null
  }

  export default function (state = initialState, action) {
    switch (action.type) {
      //getInteractionsWithAPIPaged
      case interactionWithAPIConstants.getInteractionsWithAPIPaged.REQUEST:
        return {
          ...state,
          interactionsWithAPILoading: true
        }
      case interactionWithAPIConstants.getInteractionsWithAPIPaged.SUCCESS:
        return {
          ...state,
          interactionsWithAPILoading: false,
          interactionsWithAPIPaged: action.payload.result
        }
      case interactionWithAPIConstants.getInteractionsWithAPIPaged.FAILURE:
        return {
          ...state,
          interactionsWithAPILoading: false,
          error: action.payload.result
        }
      case interactionWithAPIConstants.getInteractionsWithAPIPaged.CLEAR:
        return {
          ...state,
          interactionsWithAPILoading: false,
          interactionsWithAPIPaged: null,
        }
      //getInteractionsWithAPIList
      case interactionWithAPIConstants.getInteractionsWithAPIList.REQUEST:
        return {
          ...state,
          interactionsWithAPILoading: true
        }
      case interactionWithAPIConstants.getInteractionsWithAPIList.SUCCESS:
        return {
          ...state,
          interactionsWithAPILoading: false,
          interactionsWithAPIList: action.payload.result
        }
      case interactionWithAPIConstants.getInteractionsWithAPIList.FAILURE:
        return {
          ...state,
          interactionsWithAPILoading: false,
          error: action.payload.result
        }
      case interactionWithAPIConstants.getInteractionsWithAPIList.CLEAR:
        return {
          ...state,
          interactionsWithAPILoading: false,
          interactionsWithAPIList: null,
        }
      // createInteractionWithAPI, deleteInteractionWithAPI, restoreInteractionWithAPI, updateInteractionWithAPI
      case interactionWithAPIConstants.createInteractionWithAPI.REQUEST:
      case interactionWithAPIConstants.deleteInteractionWithAPI.REQUEST:
      case interactionWithAPIConstants.restoreInteractionWithAPI.REQUEST:
      case interactionWithAPIConstants.updateInteractionWithAPI.REQUEST:
        return {
          ...state,
          interactionWithAPICRUDLoading: true
        }
      case interactionWithAPIConstants.createInteractionWithAPI.FAILURE:
      case interactionWithAPIConstants.deleteInteractionWithAPI.FAILURE:
      case interactionWithAPIConstants.restoreInteractionWithAPI.FAILURE:
      case interactionWithAPIConstants.updateInteractionWithAPI.FAILURE:
        return {
          ...state,
          interactionWithAPICRUDLoading: false,
          error: action.payload.result
        }
      // createInteractionWithAPI 
      case interactionWithAPIConstants.createInteractionWithAPI.SUCCESS:
        return {
          ...state,
          interactionWithAPICRUDLoading: false,
          interactionWithAPICRUDvalue: {
            create : action.payload.result,
            restore : null,
            update : null,
            delete : null
          }
        }
      // restoreInteractionWithAPI,
      case interactionWithAPIConstants.restoreInteractionWithAPI.SUCCESS:
        return {
          ...state,
          interactionWithAPICRUDLoading: false,
          interactionWithAPICRUDvalue: {
            create : null,
            restore : action.payload.result,
            update : null,
            delete : null
          }
        }
      // updateInteractionWithAPI,
      case interactionWithAPIConstants.updateInteractionWithAPI.SUCCESS:
        return {
          ...state,
          interactionWithAPICRUDLoading: false,
          interactionWithAPICRUDvalue: {
            create : null,
            restore : null,
            update : action.payload.result,
            delete : null
          }
        }
      // deleteInteractionWithAPI
      case interactionWithAPIConstants.deleteInteractionWithAPI.SUCCESS:
        return {
          ...state,
          interactionWithAPICRUDLoading: false,
          interactionWithAPICRUDvalue: {
            create : null,
            restore : null,
            update : null,
            delete : action.payload.result
          }
        }
      // clearInteractionWithAPICRUDvalue
      case interactionWithAPIConstants.clearInteractionWithAPICRUDvalue: 
        return {
          ...state,
          interactionWithAPICRUDvalue : {
            create : null,
            restore : null,
            update : null,
            delete : null
          },
          interactionWithAPICRUDLoading: false,
        }  
      default:
        return state
    }
  }