import {
	reOrgAppealMissionsConstants
}from './constants'

const initialState = {
	reOrgAppealMissionsList : null,
	reOrgAppealMissionsPaged : null,
	reOrgAppealMissionsLoading : false,
	reOrgAppealMissionActionLoading : false,
	readReOrgAppealMissionResult : null,
	getReOrgAppealMissionFileLoading : false,
	getReOrgAppealMissionResponseFileLoading: false,
	reOrgAppealMissionActionResult : {
		create : null,
		register : null,
		update : null,
		response : null
	}
}

export default function (state = initialState, action){
	switch (action.type){
		// ReadReOrgAppealMission
		case reOrgAppealMissionsConstants.ReadReOrgAppealMission.REQUEST:
			return {
				...state,
				reOrgAppealMissionActionLoading : true
			}
		case reOrgAppealMissionsConstants.ReadReOrgAppealMission.SUCCESS:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				readReOrgAppealMissionResult : action.payload.result
			}
		case reOrgAppealMissionsConstants.ReadReOrgAppealMission.FAILURE:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				error          					: action.payload.result
			}
		case reOrgAppealMissionsConstants.ReadReOrgAppealMission.CLEAR:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				readReOrgAppealMissionResult : null
			}
		// CreateReOrgAppealMission
		case reOrgAppealMissionsConstants.CreateReOrgAppealMission.REQUEST:
			return {
				...state,
				reOrgAppealMissionActionLoading : true
			}
		case reOrgAppealMissionsConstants.CreateReOrgAppealMission.SUCCESS:
			return {
				...state,
				reOrgAppealMissionActionLoading : false, 
				reOrgAppealMissionActionResult : {
					create : action.payload.result,
					register : null,
					update : null,
					response : null
				}
			}
		case reOrgAppealMissionsConstants.CreateReOrgAppealMission.FAILURE:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				error          					: action.payload.result
			}
		// RegisterReOrgAppealMission 
		case reOrgAppealMissionsConstants.RegisterReOrgAppealMission.REQUEST:
			return {
				...state,
				reOrgAppealMissionActionLoading : true
			}
		case reOrgAppealMissionsConstants.RegisterReOrgAppealMission.SUCCESS:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				reOrgAppealMissionActionResult : {
					create : null,
					register : action.payload.result,
					update : null,
					response : null
				}
			}
		case reOrgAppealMissionsConstants.RegisterReOrgAppealMission.FAILURE:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				error          					  : action.payload.result
			}
		// UpdateReOrgAppealMissionByReceiver 
		case reOrgAppealMissionsConstants.UpdateReOrgAppealMissionByReceiver.REQUEST:
			return {
				...state,
				reOrgAppealMissionActionLoading : true
			}
		case reOrgAppealMissionsConstants.UpdateReOrgAppealMissionByReceiver.SUCCESS:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				reOrgAppealMissionActionResult : {
					create : null,
					register : null,
					update :  action.payload.result,
					response : null
				}
			}
		case reOrgAppealMissionsConstants.UpdateReOrgAppealMissionByReceiver.FAILURE:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				error          					: action.payload.result
			}
		// CreateReOrgAppealMissionResponse 
		case reOrgAppealMissionsConstants.CreateReOrgAppealMissionResponse.REQUEST:
			return {
				...state,
				reOrgAppealMissionActionLoading : true
			}
		case reOrgAppealMissionsConstants.CreateReOrgAppealMissionResponse.SUCCESS:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				reOrgAppealMissionActionResult : {
					create : null,
					register : null,
					update :  null,
					response : action.payload.result
				}
			}
		case reOrgAppealMissionsConstants.CreateReOrgAppealMissionResponse.FAILURE:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				error          					  : action.payload.result
			}
		case reOrgAppealMissionsConstants.CreateReOrgAppealMissionResponse.CLEAR:
			return {
				...state,
				reOrgAppealMissionActionLoading : false,
				createReOrgAppealMissionResponseResult  : null
			}
		// GetReOrgAppealMissionsList
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsList.REQUEST:
			return {
				...state,
				reOrgAppealMissionsLoading : true
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsList.SUCCESS:
			return {
				...state,
				reOrgAppealMissionsList 	   : action.payload.result,
				reOrgAppealMissionsLoading : false,
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsList.FAILURE:
			return {
				...state,
				reOrgAppealMissionsLoading : false,
				error          				   : action.payload.result
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsList.CLEAR:
			return {
				...state,
				reOrgAppealMissionsLoading : false
			}
		// GetReOrgAppealMissionsPaged, GetReOrgAppealMissionsForReOrgAppealPaged
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsPaged.REQUEST:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsForReOrgAppealPaged.REQUEST:
			return {
				...state,
				reOrgAppealMissionsLoading : true
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsPaged.SUCCESS:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsForReOrgAppealPaged.SUCCESS:
			return {
				...state,
				reOrgAppealMissionsLoading : false,
				reOrgAppealMissionsPaged   : action.payload.result
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsPaged.FAILURE:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsForReOrgAppealPaged.FAILURE:
			return {
				...state,
				reOrgAppealMissionsLoading : false,
				error          			   : action.payload.result
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsPaged.CLEAR:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionsForReOrgAppealPaged.CLEAR:
			return {
				...state,
				reOrgAppealMissionsLoading : false,
				reOrgAppealMissionsPaged   : null
			}
		// GetReOrgAppealMissionFile, GetReOrgAppealMissionFiles 
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFile.REQUEST:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFiles.REQUEST:
			return {
				...state,
				getReOrgAppealMissionFileLoading : true
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFile.SUCCESS:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFiles.SUCCESS:
			return {
				...state,
				getReOrgAppealMissionFileLoading : false
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFile.FAILURE:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFiles.FAILURE:
			return {
				...state,
				getReOrgAppealMissionFileLoading : false,
				error          			   		 : action.payload.result
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFile.CLEAR:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionFiles.CLEAR:
			return {
				...state,
				getReOrgAppealMissionFileLoading : false
			}
		// GetReOrgAppealMissionResponseFile, GetReOrgAppealMissionResponseFiles
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFile.REQUEST:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFiles.REQUEST:
			return {
				...state,
				getReOrgAppealMissionResponseFileLoading : true
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFile.SUCCESS:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFiles.SUCCESS:
			return {
				...state,
				getReOrgAppealMissionResponseFileLoading : false
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFile.FAILURE:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFiles.FAILURE:
			return {
				...state,
				getReOrgAppealMissionResponseFileLoading : false,
				error          			   		 : action.payload.result
			}
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFile.CLEAR:
		case reOrgAppealMissionsConstants.GetReOrgAppealMissionResponseFiles.CLEAR:
			return {
				...state,
				getReOrgAppealMissionResponseFileLoading : false
			}
		//ClearReOrgAppealMissionCRUDValues
		case reOrgAppealMissionsConstants.ClearReOrgAppealMissionCRUDValues:
			return {
				...state,
				readReOrgAppealMissionResult : null,
				reOrgAppealMissionActionResult : {
					create : null,
					register : null,
					update : null,
					response : null
				}
			}
		default:
			return state
	}
}
