export const i18n = {
  tableHeaderCode: {
    1: "Код",
    0: "Код",
  },
  tableHeaderUNP: {
    1: "УНП",
    0: "УНП",
  },
  tableHeaderStatus: {
    1: "Статус",
    0: "Статус",
  },
  titleModalAddItem: {
    1: "Добавить запись",
    0: "Дадаць запіс",
  },
  tableHeaderCodeISO: {
    1: "Код ИСО",
    0: "Код ІСО",
  },
  tableHeaderView: {
    1: "Вид",
    0: "Выгляд",
  },
  tableHeaderType: {
    1: "Тип",
    0: "Тып",
  },
  tableHeaderName: {
    1: "Название",
    0: "Назва",
  },
  tableHeaderMethod: {
    1: "Имя метода",
    0: "Імя метаду",
  },
  tableHeaderCharacteristic: {
    1: "Характеристики",
    0: "Характарыстыкі",
  },
  tableHeaderNameRu: {
    1: "Название на русском",
    0: "Назва па-руску",
  },
  tableHeaderFullNameRu: {
    1: "Полное название на русском",
    0: "Поўная назва па-руску",
  },
  tableHeaderShortNameRu: {
    1: "Сокращенное название на русском",
    0: "Скарочаная назва па-руску",
  },
  tableHeaderFullNameBel: {
    1: "Полное название на белорусском",
    0: "Поўная назва па-беларуску",
  },
  tableHeaderNameBel: {
    1: "Название на белорусском",
    0: "Назва па-беларуску",
  },
  tableHeaderDescriptionRu: {
	0 : "Апісанне па-руску",
	1 : "Описание на русском"
  },
  tableHeaderDescriptionBel: {
	0 : "Апісанне па-беларуску",
	1 : "Описание на белорусском"
  },
  tableHeaderShortNameBel: {
    1: "Сокращенное название на белорусском",
    0: "Скарочаная назва па-беларуску",
  },
  tableHeaderChooise: {
    1: "Выбор организации",
    0: "Выбар арганізацыі",
  },
  orgSearchNotFound: {
    0: "Арганізацыі не знойдзеныя",
    1: "Организации не найдены",
  },
  reOrgSearchPlaceholder: {
    0: "Пачніце ўводзіць назву або УНП арганізацыі",
    1: "Начните вводить название или УНП организации",
  },
  undefinedError:{
    1 : "Неизвестная ошибка. Попробуйте ещё раз или обратитесь к администратору со скриншотом данной ошибки.",
		0 : "Невядомая памылка. Паспрабуйце яшчэ раз, або звярніцеся да адміністратара з скрыншоце дадзенай памылкі."
  },
  orgNotActive: {
    0: "Дадзеная арганізацыя недаступная з-за яе статусу ў АДР.",
    1: "Данная организация недоступна ввиду её статуса в ЕГР."
  },
  orgHasNoUsers: {
    0: "Гэтая арганізацыя яшчэ не прайшла рэгістрацыю ў сістэме",
    1: "Данная организация ещё не прошла регистрацию в системе."
  },
  orgHasNoWorkShedule: {
    0: "Гэтая арганізацыя яшчэ не завяршыла працэс рэгістрацыі ў сістэме",
    1: "Данная организация ещё не завершила процесс регистрации в системе."
  },
  maxReOrgCountNotification: {
    1: "Максимальное количество организаций для перенаправления {0}",
    0: "Максімальная колькасць арганізацый для перанакіравання {0}"
  },
  tableHeaderParentOrg: {
    1: "Ваша организация является дочерней для",
    0: "Ваша арганізацыя з'яўляецца даччынай для",
  },
  tableHeaderInstitutionOrg: {
    1: "Ваша организация является подведомственной для",
    0: "Ваша арганізацыя з'яўляецца падведамнай для",
  },
  tableHeaderIsEnteredManually: {
    1: "Организация создана вручную",
    0: "Арганізацыя створана ўручную",
  },
  tableHeaderIsActive: {
    1: "Возможность подачи обращения",
    0: "Магчымасць падачы звароту",
  },
  tableHeaderReOrgCreator: {
    1: "Организация-создатель",
    0: "Арганізацыя-стваральнік",
  },
  tableHeaderDateStart: {
    1: "Дата начала действия",
    0: "Дата пачатку дзеяння",
  },
  tableHeaderDateEnd: {
    1: "Дата окончания действия",
    0: "Дата заканчэння дзеяння",
  },
  tableHeaderDateUpdate: {
    1: "Дата изменения",
    0: "Дата змены",
  },
  placeholderSearchInput: {
    1: "Код | Название (Минимальная длина 3 символа)",
    0: "Код | Назва (Мінімальная даўжыня 3 сімвала)",
  },
  buttonAdd: {
    1: "Добавить",
    0: "Дадаць",
  },
  buttonEdit: {
    1: "Редактировать",
    0: "Рэдагаваць",
  },
  buttonDelete: {
    1: "Удалить",
    0: "Выдаліць",
  },
  buttonRestore: {
    1: "Восстановить",
    0: "Аднавіць",
  },
  buttonCancel: {
    1: "Отмена",
    0: "Адмена",
  },
  buttonSave: {
    1: "Сохранить",
    0: "Захаваць",
  },
  messageCodeValidate: {
    1: "Код заполнен некорректно! (1 - 4 символа)",
    0: "Код запоўнены некарэктна! (1 - 4 сімвала)",
  },
  messageCodeISOValidate: {
    1: "Код ИСО заполнен некорректно! (2 символа)",
    0: "Код ІСО запоўнены некарэктна! (2 сімвала)",
  },
  messageNameRuValidate: {
    1: "Пожалуйста, введите название на русском! (длина строки 4 - 200)",
    0: "Калі ласка, увядзіце назву па-руску! (даўжыня радка 4 - 200)",
  },
  messageNameBelValidate: {
    1: "Пожалуйста, введите название на белорусском! (длина строки 4 - 200)",
    0: "Калі ласка, увядзіце назву па-беларуску! (даўжыня радка 4 - 200)",
  },
  messageDescriptionRuValidate: {
	1 : 'Пожалуйста, введите описание на русском! (длина строки 4 - 200)',
	0 : 'Калі ласка, увядзіце апісанне па-руску! (даўжыня радка 4 - 200)'
  },
  messageDescriptionBelValidate: {
	1 : 'Пожалуйста, введите описание на белорусском! (длина строки 4 - 200)',
	0 : 'Калі ласка, увядзіце апісанне па-беларуску! (даўжыня радка 4 - 200)'
  },
  messageDateStartValidate: {
    1: "Пожалуйста, укажите дату начала действия!",
    0: "Калі ласка, пакажыце дату пачатку дзеяння!",
  },
  messageItemUpdateSuccses: {
    1: "Запись успешно изменена",
    0: "Запіс паспяхова змененая",
  },
  messageItemAddSuccses: {
    1: "Запись успешно добавлена",
    0: "Запіс паспяхова дададзеная",
  },
  messageItemDeleteSuccses: {
    1: "Запись успешно удалена",
    0: "Запіс паспяхова выдаленая",
  },
  messageItemRestoreSuccses: {
    1: "Запись успешно восстановлена",
    0: "Запіс паспяхова адноўлена",
  },
  messageChooiseDeleteItem: {
    1: "Вы уверены, что хотите удалить данную запись?",
    0: "Вы ўпэўненыя, што жадаеце выдаліць дадзены запіс?",
  },
  messageChooiseUpdateItem: {
    1: "Вы уверены, что хотите восстановить данную запись?",
    0: "Вы ўпэўненыя, што жадаеце аднавіць дадзены запіс?",
  },
  messageDataNotChanged: {
    0: "Уведзеныя вамі дадзеныя цалкам адпавядаюць першапачатковым.",
    1: "Введенные вами данные полностью соответствуют изначальным.",
  },
  titleModalEditItem: {
    1: "Редактировать запись",
    0: "Рэдагаваць запіс",
  },
  unpError: {
    0: "Увядзіце карэктны УНП!",
    1: "Введите корректный УНП!",
  },
  themeOgul: {
    1: "Тема обращения",
    0: "Тэма звароту",
  },
  themeOgulLabel: {
    1: "Выберите тему обращения",
    0: "Выберыце тэму звароту",
  },
  reMissionStatusCRUDMessage: {
    create: {
      1: "Статус поручения успешно добавлен",
      0: "Статус даручэння паспяхова дададзены"
    },
    restore: {
      1: "Статус поручения успешно восстановлен",
      0: "Статус даручэння паспяхова адноўлены"
    },
    update: {
      1: "Статус поручения успешно изменен",
      0: "Статус даручэння паспяхова зменены"
    },
    delete: {
      1: "Статус поручения успешно удален",
      0: "Статус даручэння паспяхова выдалены"
    }
  },
  noDataChange: {
    1 : "Данные не были изменены",
    0 : "Дадзеныя не былі зменены"
},
}
export default i18n